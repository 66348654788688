// import {  } from "vue"
import { getIndexInfo, getIndexInfo1, getIndexInfo2 } from "@/api/index/index";
export default {
  name: "Layout",
  components: {},
  data: () => {
    return {
      table: false,
      infoList: {},
    };
  },
  watch: {},
  created() {
    this.deviceType = JSON.parse(localStorage.getItem("deviceType"));
    if (this.deviceType.type == "rfl") {
      let rfinfolist = {
        V_ALARM: "",
        V_SOUREC: "",
        V_RCL1: "",
        V_RCL2: "",
        V_RCL3: "",
        V_RCL4: "",
        V_GONGGONG__p__ZGXK02: "",
        V_TJ: "",
        V_TJ1: "",
        V_TJ2: "",
        V_TJ3: "",
        V_TJ4: "",
        V_SP: "",
        V_RS1: "",
        V_RS2: "",
        V_RS3: "",
        V_RS4: "",
        V_BCS1: "",
        V_BCS2: "",
        V_BCS3: "",
        V_BCS4: "",
        V_RS__p__RSFB1__p__XK01: "",
        V_RS__p__RSFB2__p__XK01: "",
        V_RS__p__RSFB3__p__XK01: "",
        V_RS__p__RSFB4__p__XK01: "",
        V_RS__p__RSFB1__p__XK02: "",
        V_RS__p__RSFB2__p__XK02: "",
        V_RS__p__RSFB3__p__XK02: "",
        V_RS__p__RSFB4__p__XK02: "",
        V_RS__p__RSFB1__p__XK03: "",
        V_RS__p__RSFB2__p__XK03: "",
        V_RS__p__RSFB3__p__XK03: "",
        V_RS__p__RSFB4__p__XK03: "",
        V_RS__p__RSFB1__p__XK04: "",
        V_RS__p__RSFB2__p__XK04: "",
        V_RS__p__RSFB3__p__XK04: "",
        V_RS__p__RSFB4__p__XK04: "",
        V_RS__p__RSFB1__p__XK05: "",
        V_RS__p__RSFB2__p__XK05: "",
        V_RS__p__RSFB3__p__XK05: "",
        V_RS__p__RSFB4__p__XK05: "",
        V_PJZB: "",
        V_GONGGONG__p__MAN1: {
          AV: "",
          OUTB: "",
          OUTT: "",
          RM: "",
        },
        MAN2: {
          AV: "",
          OUTB: "",
          OUTT: "",
          RM: "",
        },
        MAN3: {
          AV: "",
          OUTB: "",
          OUTT: "",
          RM: "",
        },
        RSFB1__p__MAN1: "",
        RSFB2__p__MAN1: "",
        RSFB3__p__MAN1: "",
        RSFB4__p__MAN1: "",
        RSFB1__p__MAN2: "",
        RSFB2__p__MAN2: "",
        RSFB3__p__MAN2: "",
        RSFB4__p__MAN2: "",
        ZGXK01: "",
        ZGXK03: "",
        V_STATE: "",
        V_OTHER1: "",
        V_HLLIM: "",
        V_NBCS: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "rql") {
      let rfinfolist = {
        BCS1__p__BCSYH_SHM1: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr__: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr__: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL: "",
        BCS1__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL: "",
        MCALARM: "",
        MCBCS11: "",
        MCBCS11__p__QSYH1: "",
        MCCYSZ: "",
        MCFHXT: "",
        MCGEISHUI: "",
        MCKRBCL: "",
        MCMAN__p__MAN1: "",
        MCMAN__p__MAN2: "",
        MCMAN__p__MAN3: "",
        MCMAN__p__MAN4: "",
        MCMAN__p__MAN5: "",
        MCMAN__p__MAN6: "",
        MCMAN__p__MAN7: "",
        MCMAN__p__MAN8: "",
        MCMAN__p__MAN9: "",
        MCMAN__p__MAN10: "",
        MCMAN__p__MAN11: "",
        MCMAN__p__MAN12: "",
        MCMAN__p__MAN13: "",
        MCMAN__p__MAN14: "",
        MCMAN__p__MAN15: "",
        MCMAN__p__MAN16: "",
        MCMAN__p__MAN17: "",
        MCMAN__p__MAN18: "",
        MCMAN__p__MAN19: "",
        MCMAN__p__MAN20: "",
        MCMAN__p__MAN21: "",
        MCMAN__p__MAN22: "",
        MCMAN__p__MAN23: "",
        MCMAN__p__MAN24: "",
        MCMAN__p__MAN25: "",
        MCMAN__p__MAN26: "",
        MCMAN__p__MAN27: "",
        MCMAN__p__MAN28: "",
        MCMAN__p__MAN29: "",
        MCMAN__p__MAN30: "",
        MCMAN__p__MAN31: "",
        MCMAN__p__MAN32: "",
        MCMAN__p__MAN33: "",
        MCMAN__p__MAN34: "",
        MCMAN__p__MAN35: "",
        MCQIWEN: "",
        MCQK: "",
        MCQK__p__QK1: "",
        MCQK__p__QK2: "",
        MCQK__p__QK3: "",
        MCQK__p__QK4: "",
        MCQK__p__QK5: "",
        MCQK__p__QK6: "",
        MCQK__p__QK7: "",
        MCQK__p__QK8: "",
        MCQK__p__QK9: "",
        MCQK__p__QK10: "",
        MCQK__p__QK11: "",
        MCQK__p__QK12: "",
        MCQK__p__QK13: "",
        MCQK__p__QK14: "",
        MCQK__p__QK15: "",
        MCQK__p__QK16: "",
        MCQK__p__QK17: "",
        MCQK__p__QK18: "",
        MCQK__p__QK19: "",
        MCQK__p__QK20: "",
        MCQK__p__QK21: "",
        MCQK__p__QK22: "",
        MCQK__p__QK23: "",
        MCQK__p__QK24: "",
        MCRANQI: "",
        MCRANQI__p__MQFMFD: "",
        MCRANQI__p__MQYFD: "",
        MCRANQI__p__MQYLFD: "",
        MCRANQI__p__QKMCFOP1: "",
        MCRCL1: "",
        MCRCL1__p__GLMQRCL1: "",
        MCRCL1__p__GLMQRCL2: "",
        MCRCL1__p__GLMQRCL3: "",
        MCRCL2: "",
        MCRCL3: "",
        MCRECIPE: "",
        MCRSF: "",
        MCRSF__p__RSF1: "",
        MCRSF__p__RSF2: "",
        MCRSF__p__RSF3: "",
        MCRSF__p__RSF4: "",
        MCRSF__p__RSF5: "",
        MCSO2NOX: "",
        MCSONGFENG: "",
        MCSONGFENG__p__SFDBFD: "",
        MCSOUREC: "",
        MCSPCL: "",
        MCSQL_TJ: "",
        MCTME: "",
        MCXK__p__XK1: "",
        MCXK__p__XK2: "",
        MCXK__p__XK3: "",
        MCXK__p__XK4: "",
        MCXK__p__XK5: "",
        MCXK__p__XK6: "",
        MCXK__p__XK7: "",
        MCXK__p__XK8: "",
        MCXK__p__XK9: "",
        MCXK__p__XK10: "",
        MCXK__p__XK11: "",
        MCXK__p__XK12: "",
        MCXK__p__XK13: "",
        MCXK__p__XK14: "",
        MCXK__p__XK15: "",
        MCXK__p__XK16: "",
        MCXK__p__XK17: "",
        MCXK__p__XK18: "",
        MCXK__p__XK19: "",
        MCXK__p__XK20: "",
        MCXK__p__XK21: "",
        MCXK__p__XK22: "",
        MCXK__p__XK23: "",
        MCXK__p__XK24: "",
        MCXK__p__XK25: "",
        MCXK__p__XK26: "",
        MCXK__p__XK27: "",
        MCXK__p__XK28: "",
        MCXK__p__XK29: "",
        MCXK__p__XK30: "",
        MCXNKH: "",
        MCYINFENG: "",
        MCYINFENG__p__YFDBFD: "",
        MCZAIRE: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "jrl") {
      let rfinfolist = {
        G_ALARM: "",
        G_BCS: "",
        G_BCS__p__BCSYH_SHM1: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL: "",
        BCSYH_SHM1__p__BCSYHn__ll__4__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__4__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__4__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__5__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__5__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__5__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__6__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__6__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__6__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__7__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__7__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__7__rr____p__ZDBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__8__rr__: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__8__rr____p__DXBL: "",
        G_BCS__p__BCSYH_SHM1__p__BCSYHn__ll__8__rr____p__ZDBL: "",
        G_BJL: "",
        G_CJLL: "",
        G_KHTJ: "",
        G_KHTJ__p__GSMX1: "",
        MAN1: "",
        MAN2: "",
        MAN3: "",
        MAN4: "",
        MAN5: "",
        MAN6: "",
        MAN7: "",
        MAN8: "",
        MAN9: "",
        MAN10: "",
        MAN11: "",
        MAN12: "",
        MAN13: "",
        MAN14: "",
        MAN15: "",
        MAN16: "",
        MAN17: "",
        MAN18: "",
        MAN19: "",
        MAN20: "",
        MAN21: "",
        MAN22: "",
        MAN23: "",
        MAN24: "",
        MAN25: "",
        MAN26: "",
        G_MCSOTHER: "",
        QK1: "",
        QK2: "",
        QK3: "",
        QK4: "",
        QK5: "",
        QK6: "",
        QK7: "",
        QK8: "",
        QK9: "",
        QK10: "",
        QK11: "",
        QK12: "",
        QK13: "",
        QK14: "",
        QK15: "",
        QK16: "",
        QK17: "",
        QK18: "",
        QK19: "",
        QK20: "",
        QK21: "",
        QK22: "",
        QK23: "",
        QK24: "",
        RSF1: "",
        RSF2: "",
        RSF3: "",
        RSF4: "",
        RSF5: "",
        G_MCSXK__p__TYYC1: "",
        G_MCSXK__p__TYYC2: "",
        G_MCSXK__p__TYYC3: "",
        G_MCSXK__p__TYYC4: "",
        XK1: "",
        XK2: "",
        XK3: "",
        XK4: "",
        XK5: "",
        XK6: "",
        XK7: "",
        XK8: "",
        XK9: "",
        XK10: "",
        XK11: "",
        XK12: "",
        XK13: "",
        XK14: "",
        XK15: "",
        XK16: "",
        XK17: "",
        XK18: "",
        XK19: "",
        XK20: "",
        XK21: "",
        XK22: "",
        XK23: "",
        XK24: "",
        XK25: "",
        XK26: "",
        G_PFJ: "",
        G_PVCL: "",
        G_RCLMQZG__p__RCL11: "",
        G_RCLMQZG__p__RCL12: "",
        G_RCLMQZG__p__RCL21: "",
        G_RCLMQZG__p__RCL22: "",
        G_RCLMQZG__p__RCL31: "",
        G_RCLMQZG__p__RCL41: "",
        G_RCLMQZG__p__RCL32: "",
        G_RCLMQZG__p__RCL42: "",
        G_RECIPE: "",
        G_SOUREC: "",
        G_TEMP: "",
        G_TEMP__p__CGT: "",
        G_TEMP__p__RLGWXT: "",
        G_TME: "",
        GSMX1: {
          GSM: 0,
        },
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "cfb") {
      let rfinfolist = {
        MCSALARM: "",
        MCSAO: "",
        MCSBCS: "",
        MCSBCS__p__BCSYH_SHM1: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr__: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL: "",
        MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__ZDBL: "",
        MCSBY: "",
        MCSCWKZ: "",
        MCSCZKZ: "",
        MCSDA: "",
        MCSDLXT: "",
        MCSFENG: "",
        MCSFENG__p__YCFDB: "",
        MCSGEIMEI: "",
        MCSGEIMEI__p__CWFH: "",
        MCSGEIMEI__p__QKMCFOP1: "",
        MCSGEISHUI: "",
        MCSJNKH: "",
        MCSMAN__p__MAN1: "",
        MCSMAN__p__MAN2: "",
        MCSMAN__p__MAN3: "",
        MCSMAN__p__MAN4: "",
        MCSMAN__p__MAN5: "",
        MCSMAN__p__MAN6: "",
        MCSMAN__p__MAN7: "",
        MCSMAN__p__MAN8: "",
        MCSMAN__p__MAN9: "",
        MCSMAN__p__MAN10: "",
        MCSMAN__p__MAN11: "",
        MCSMAN__p__MAN12: "",
        MCSMAN__p__MAN13: "",
        MCSMAN__p__MAN14: "",
        MCSMAN__p__MAN15: "",
        MCSMAN__p__MAN16: "",
        MCSMAN__p__MAN17: "",
        MCSMAN__p__MAN18: "",
        MCSMAN__p__MAN19: "",
        MCSMAN__p__MAN20: "",
        MCSMAN__p__MAN21: "",
        MCSMAN__p__MAN22: "",
        MCSMAN__p__MAN23: "",
        MCSMAN__p__MAN24: "",
        MCSMAN__p__MAN25: "",
        MCSMAN__p__MAN26: "",
        MCSMAN__p__MAN27: "",
        MCSMAN__p__MAN28: "",
        MCSMAN__p__MAN29: "",
        MCSMAN__p__MAN30: "",
        MCSMAN__p__MAN31: "",
        MCSMAN__p__MAN32: "",
        MCSMAN__p__MAN33: "",
        MCSMAN__p__MAN34: "",
        MCSMAN__p__MAN35: "",
        MCSMAN__p__MAN36: "",
        MCSMAN__p__MAN37: "",
        MCSMAN__p__MAN38: "",
        MCSMAN__p__MAN39: "",
        MCSMAN__p__MAN40: "",
        MCSO2NOX: "",
        MCSQIWEN: "",
        MCSRECIPE: "",
        MCSRPQ: "",
        MCSRPQ__p__PID01: "",
        MCSRPQ__p__PID02: "",
        MCSRPQ__p__PID03: "",
        MCSRPQ__p__PID04: "",
        MCSRPQ__p__PID05: "",
        MCSRPQ__p__PID06: "",
        MCSRPQ__p__PID07: "",
        MCSRPQ__p__PID08: "",
        MCSRPQ__p__QK0A: "",
        MCSRPQ__p__QK0B: "",
        MCSRPQ__p__QK0C: "",
        MCSRPQ__p__QK0D: "",
        MCSRPQ__p__QK0E: "",
        MCSRPQ__p__QK0F: "",
        MCSRPQ__p__QK01: "",
        MCSRPQ__p__QK02: "",
        MCSRPQ__p__QK03: "",
        MCSRPQ__p__QK04: "",
        MCSRPQ__p__QK05: "",
        MCSRPQ__p__QK06: "",
        MCSRPQ__p__QK07: "",
        MCSRPQ__p__QK08: "",
        MCSRPQ__p__QK09: "",
        MCSRPQ__p__RSF0A: "",
        MCSRPQ__p__RSF0B: "",
        MCSRPQ__p__RSF0C: "",
        MCSRPQ__p__RSF0D: "",
        MCSRPQ__p__RSF0E: "",
        MCSRPQ__p__RSF0F: "",
        MCSRPQ__p__RSF0G: "",
        MCSRPQ__p__RSF0I: "",
        MCSRPQ__p__RSF0J: "",
        MCSRPQ__p__RSF01: "",
        MCSRPQ__p__RSF02: "",
        MCSRPQ__p__RSF03: "",
        MCSRPQ__p__RSF04: "",
        MCSRPQ__p__RSF05: "",
        MCSRPQ__p__RSF06: "",
        MCSRPQ__p__RSF07: "",
        MCSRPQ__p__RSF08: "",
        MCSRPQ__p__RSF09: "",
        MCSRSXL: "",
        MCSSOURCE: "",
        MCSTIMESET: "",
        MCSXK__p__XK0A: "",
        MCSXK__p__XK0B: "",
        MCSXK__p__XK0C: "",
        MCSXK__p__XK0D: "",
        MCSXK__p__XK0E: "",
        MCSXK__p__XK0F: "",
        MCSXK__p__XK0G: "",
        MCSXK__p__XK0H: "",
        MCSXK__p__XK0I: "",
        MCSXK__p__XK0J: "",
        MCSXK__p__XK0K: "",
        MCSXK__p__XK0L: "",
        MCSXK__p__XK0M: "",
        MCSXK__p__XK0N: "",
        MCSXK__p__XK0O: "",
        MCSXK__p__XK0P: "",
        MCSXK__p__XK0Q: "",
        MCSXK__p__XK0R: "",
        MCSXK__p__XK0S: "",
        MCSXK__p__XK0T: "",
        MCSXK__p__XK0U: "",
        MCSXK__p__XK01: "",
        MCSXK__p__XK02: "",
        MCSXK__p__XK03: "",
        MCSXK__p__XK04: "",
        MCSXK__p__XK05: "",
        MCSXK__p__XK06: "",
        MCSXK__p__XK07: "",
        MCSXK__p__XK08: "",
        MCSXK__p__XK09: "",
        MCSYCL: "",
        MCSZKL: "",
        MCSZXJK: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "mfl") {
      let rfinfolist = {
        IOG: "",
        KHG: "",
        MKG: "",
        MKG__p__ECFAK: "",
        MKG__p__ECFBK: "",
        MKG__p__ECFCK: "",
        MKG__p__ECFDK: "",
        MKG__p__ECFEK: "",
        MKG__p__MFL_A_SPJ: "",
        MKG__p__MFL_B_SPJ: "",
        MKG__p__MFL_C_SPJ: "",
        MKG__p__MFL_D_SPJ: "",
        MKG__p__MFL_E_SPJ: "",
        MKG__p__MYP_A_SPJ: "",
        MKG__p__MYP_B_SPJ: "",
        MKG__p__MYP_C_SPJ: "",
        MKG__p__MYP_D_SPJ: "",
        MKG__p__MYP_E_SPJ: "",
        MKG__p__NH3SP_0: "",
        MKG__p__QBW_YC1: "",
        MKG__p__YCFA_SP: "",
        MKG__p__YCFB_SP: "",
        MKG__p__ZQFO: "",
        MKG__p__ZQFS: "",
        MKG__p__ZQP_YC1: "",
        MKG__p__ZQT1ASP: "",
        MKG__p__ZQT1BSP: "",
        MKG__p__ZQT_YC1: "",
        MKG__p__ZQT_YC2: "",
        RECIPE: "",
        RQM: "",
        RQM__p__CKT_A_QK1: "",
        RQM__p__CKT_B_QK1: "",
        RQM__p__CKT_C_QK1: "",
        RQM__p__CKT_D_QK1: "",
        RQM__p__CKT_E_QK1: "",
        RQM__p__CKT_MAN1: "",
        RQM__p__CKT_MAN2: "",
        RQM__p__CKT_MAN3: "",
        RQM__p__CKT_MAN4: "",
        RQM__p__CKT_MAN5: "",
        RQM__p__ECF_MAN1: "",
        RQM__p__ECF_MAN2: "",
        RQM__p__ECF_MAN3: "",
        RQM__p__ECF_MAN4: "",
        RQM__p__ECF_MAN5: "",
        RQM__p__ECF_QK1: "",
        RQM__p__ECF_QK2: "",
        RQM__p__ECF_QK3: "",
        RQM__p__ECF_QK4: "",
        RQM__p__ECF_QK5: "",
        RQM__p__GMJ_MAN1: "",
        RQM__p__GMJ_MAN2: "",
        RQM__p__GMJ_MAN3: "",
        RQM__p__GMJ_MAN4: "",
        RQM__p__GMJ_MAN5: "",
        RQM__p__GML_A_QK1: "",
        RQM__p__GML_B_QK1: "",
        RQM__p__GML_C_QK1: "",
        RQM__p__GML_D_QK1: "",
        RQM__p__GML_E_QK1: "",
        RQM__p__LTP_MAN1: "",
        RQM__p__LTP_MAN2: "",
        RQM__p__LTP_QK1: "",
        RQM__p__LTP_RSF1: "",
        RQM__p__MFL_A_QK1: "",
        RQM__p__MFL_B_QK1: "",
        RQM__p__MFL_C_QK1: "",
        RQM__p__MFL_D_QK1: "",
        RQM__p__MFL_E_QK1: "",
        RQM__p__MFL_MAN1: "",
        RQM__p__MFL_MAN2: "",
        RQM__p__MFL_MAN3: "",
        RQM__p__MFL_MAN4: "",
        RQM__p__MFL_MAN5: "",
        RQM__p__MGP_MAN1: "",
        RQM__p__MGP_QK1: "",
        RQM__p__MYP_MAN1: "",
        RQM__p__MYP_MAN2: "",
        RQM__p__MYP_MAN3: "",
        RQM__p__MYP_MAN4: "",
        RQM__p__MYP_MAN5: "",
        RQM__p__QBW_MAN1: "",
        RQM__p__QBW_MAN2: "",
        RQM__p__TL_MAN1: "",
        RQM__p__TL_MAN2: "",
        RQM__p__TL_QK1: "",
        RQM__p__TL_QK2: "",
        RQM__p__TX_MAN1: "",
        RQM__p__TX_MAN2: "",
        RQM__p__TX_QK1: "",
        RQM__p__YCF_MAN1: "",
        RQM__p__YCF_MAN2: "",
        RQM__p__YCF_QK1: "",
        RQM__p__YCF_QK2: "",
        RQM__p__YCF_RSF1: "",
        RQM__p__YQO_MAN1: "",
        RQM__p__YQO_MAN2: "",
        RQM__p__YQO_QK1: "",
        RQM__p__YQO_RSF1: "",
        RQM__p__ZQP_QK1: "",
        RQM__p__ZQP_QK2: "",
        RQM__p__ZQP_QK3: "",
        RQM__p__ZQT_MAN1: "",
        RQM__p__ZQT_MAN2: "",
        RQM__p__ZQT_MAN3: "",
        RQM__p__ZQT_MAN4: "",
        RQM__p__ZQT_MAN5: "",
        RQM__p__ZQT_MAN6: "",
        RQM__p__ZQT_QK1: "",
        RQM__p__ZQT_QK2: "",
        RQM__p__ZQT_QK3: "",
        RQM__p__ZQT_QK4: "",
        RQM__p__ZQT_QK5: "",
        RQM__p__ZRQ_RSF1: "",
        XK__p__CKT_XK1: "",
        XK__p__CKT_XK2: "",
        XK__p__CKT_XK3: "",
        XK__p__CKT_XK4: "",
        XK__p__CKT_XK5: "",
        XK__p__GMJ_XK1: "",
        XK__p__GMJ_XK2: "",
        XK__p__GMJ_XK3: "",
        XK__p__GMJ_XK4: "",
        XK__p__GMJ_XK5: "",
        XK__p__GMJ_XK6: "",
        XK__p__LTP_XK1: "",
        XK__p__MFL_XK1: "",
        XK__p__MFL_XK2: "",
        XK__p__MFL_XK3: "",
        XK__p__MFL_XK4: "",
        XK__p__MFL_XK5: "",
        XK__p__MYP_XK1: "",
        XK__p__MYP_XK2: "",
        XK__p__MYP_XK3: "",
        XK__p__MYP_XK4: "",
        XK__p__MYP_XK5: "",
        XK__p__QBW_XK1: "",
        XK__p__QBW_XK2: "",
        XK__p__QBW_XK3: "",
        XK__p__TL_XK1: "",
        XK__p__TL_XK2: "",
        XK__p__TX_XK1: "",
        XK__p__TX_XK2: "",
        XK__p__YCF_XK1: "",
        XK__p__YCF_XK2: "",
        XK__p__YQO_XK1: "",
        XK__p__YQO_XK2: "",
        XK__p__ZQF_XK1: "",
        XK__p__ZQP_XK1: "",
        XK__p__ZQP_XK2: "",
        XK__p__ZQT_XK1: "",
        XK__p__ZQT_XK2: "",
        XK__p__ZQT_XK3: "",
        XK__p__ZQT_XK4: "",
        XK__p__ZQT_XK5: "",
        XK__p__ZQT_XK6: "",
        XK__p__ZQT_XK7: "",
        XK__p__ZQT_XK8: "",
        XK__p__ZQT_XK9: "",
        XK__p__ZQT_XK10: "",
        YHG: "",
        YHG__p__BCSYH_SHM1: "",
        YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__: "",
        YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL: "",
        YHG__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "fyf") {
      let rfinfolist = {
        DB1M__p__XK01: "",
        DB1M__p__XK02: "",
        DB1M__p__XK03: "",
        DB1M__p__RSF01: "",
        DB1M__p__RSF02: "",
        DB1M__p__RSF03: "",
        DB1M__p__PID01: "",
        DB1M__p__PID02: "",
        DB1M__p__PID03: "",
        DB1M__p__QK01: "",
        DB1M__p__QK02: "",
        DB1M__p__QK03: "",
        DB1M__p__MAN01: "",
        DB1M__p__MAN02: "",
        DB1M__p__MAN03: "",
        DB1M__p__XK01__p__MHZSY_0: "",
        DB1M__p__XK02__p__MHZSY_0: "",
        DB1M__p__XK03__p__MHZSY_0: "",
        DB1V: "",
        DB1S: "",
        DB1C: "",
        DB1M__p__GC01: "",
        DB1M__p__hscharc_0: "",
        PUB: "",
        RECIPE: "",
        DB1S__p__R2201B: "",
        DB2M__p__XK01: "",
        DB2M__p__XK02: "",
        DB2M__p__XK03: "",
        DB2M__p__RSF01: "",
        DB2M__p__RSF02: "",
        DB2M__p__RSF03: "",
        DB2M__p__PID01: "",
        DB2M__p__QK01: "",
        DB2M__p__QK02: "",
        DB2M__p__QK03: "",
        DB2M__p__MAN01: "",
        DB2M__p__MAN02: "",
        DB2M__p__MAN03: "",
        DB2M__p__XK01__p__MHZSY_0: "",
        DB2M__p__XK02__p__MHZSY_0: "",
        DB2M__p__XK03__p__MHZSY_0: "",
        DB2M__p__GC01: "",
        DB2M__p__hscharc_0: "",
        DB3M__p__XK01: "",
        DB3M__p__XK02: "",
        DB3M__p__XK03: "",
        DB3M__p__RSF01: "",
        DB3M__p__RSF02: "",
        DB3M__p__RSF03: "",
        DB3M__p__PID01: "",
        DB3M__p__QK01: "",
        DB3M__p__QK02: "",
        DB3M__p__QK03: "",
        DB3M__p__MAN01: "",
        DB3M__p__MAN02: "",
        DB3M__p__MAN03: "",
        DB3M__p__XK01__p__MHZSY_0: "",
        DB3M__p__XK02__p__MHZSY_0: "",
        DB3M__p__XK03__p__MHZSY_0: "",
        DB3M__p__GC01: "",
        DB3M__p__hscharc_0: "",
        DB4M__p__XK01: "",
        DB4M__p__XK02: "",
        DB4M__p__XK03: "",
        DB4M__p__RSF01: "",
        DB4M__p__RSF02: "",
        DB4M__p__RSF03: "",
        DB4M__p__PID01: "",
        DB4M__p__QK01: "",
        DB4M__p__QK02: "",
        DB4M__p__QK03: "",
        DB4M__p__MAN01: "",
        DB4M__p__MAN02: "",
        DB4M__p__MAN03: "",
        DB4M__p__XK01__p__MHZSY_0: "",
        DB4M__p__XK02__p__MHZSY_0: "",
        DB4M__p__XK03__p__MHZSY_0: "",
        DB4M__p__GC01: "",
        DB4M__p__hscharc_0: "",
        DB5M__p__XK01: "",
        DB5M__p__XK02: "",
        DB5M__p__XK03: "",
        DB5M__p__RSF01: "",
        DB5M__p__RSF02: "",
        DB5M__p__RSF03: "",
        DB5M__p__PID01: "",
        DB5M__p__QK01: "",
        DB5M__p__QK02: "",
        DB5M__p__QK03: "",
        DB5M__p__MAN01: "",
        DB5M__p__MAN02: "",
        DB5M__p__MAN03: "",
        DB5M__p__XK01__p__MHZSY_0: "",
        DB5M__p__XK02__p__MHZSY_0: "",
        DB5M__p__XK03__p__MHZSY_0: "",
        DB5M__p__GC01: "",
        DB5M__p__hscharc_0: "",
        DB6M__p__XK01: "",
        DB6M__p__XK02: "",
        DB6M__p__XK03: "",
        DB6M__p__RSF01: "",
        DB6M__p__RSF02: "",
        DB6M__p__RSF03: "",
        DB6M__p__PID01: "",
        DB6M__p__QK01: "",
        DB6M__p__QK02: "",
        DB6M__p__QK03: "",
        DB6M__p__MAN01: "",
        DB6M__p__MAN02: "",
        DB6M__p__MAN03: "",
        DB6M__p__XK01__p__MHZSY_0: "",
        DB6M__p__XK02__p__MHZSY_0: "",
        DB6M__p__XK03__p__MHZSY_0: "",
        DB6M__p__GC01: "",
        DB6M__p__hscharc_0: "",
        DB2C: "",
        DB3C: "",
        DB4C: "",
        DB5C: "",
        DB6C: "",
        DB2S: "",
        DB2S__p__R2201B: "",
        DB3S: "",
        DB3S__p__R2201B: "",
        DB4S: "",
        DB4S__p__R2201B: "",
        DB5S: "",
        DB5S__p__R2201B: "",
        DB6S: "",
        DB6S__p__R2201B: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "jhf") {
      let rfinfolist = {
        DEVICE1M__p__GC01: "",
        DEVICE1M__p__MAN01: "",
        DEVICE1M__p__MAN02: "",
        DEVICE1M__p__MAN03: "",
        DEVICE1M__p__MAN04: "",
        DEVICE1M__p__MAN05: "",
        DEVICE1M__p__MAN06: "",
        DEVICE1M__p__MAN07: "",
        DEVICE1M__p__PID01: "",
        DEVICE1M__p__PID02: "",
        DEVICE1M__p__PID03: "",
        DEVICE1M__p__QK01: "",
        DEVICE1M__p__QK02: "",
        DEVICE1M__p__QK03: "",
        DEVICE1M__p__QK04: "",
        DEVICE1M__p__QK05: "",
        DEVICE1M__p__QK06: "",
        DEVICE1M__p__RSF01: "",
        DEVICE1M__p__RSF02: "",
        DEVICE1M__p__RSF03: "",
        DEVICE1M__p__XK01: "",
        DEVICE1M__p__XK02: "",
        DEVICE1M__p__XK03: "",
        DEVICE1M__p__XK04: "",
        DEVICE1M__p__XK05: "",
        DEVICE1M__p__XK06: "",
        DEVICE1M__p__XK07: "",
        DEVICE1M__p__XK08: "",
        DEVICE1M__p__XK09: "",
        DEVICE1S: "",
        DEVICE1V: "",
        DEVICE2M__p__MAN01: "",
        DEVICE2M__p__MAN02: "",
        DEVICE2M__p__MAN03: "",
        DEVICE2M__p__MAN04: "",
        DEVICE2M__p__MAN05: "",
        DEVICE2M__p__MAN06: "",
        DEVICE2M__p__MAN07: "",
        DEVICE2M__p__PID01: "",
        DEVICE2M__p__PID02: "",
        DEVICE2M__p__PID03: "",
        DEVICE2M__p__QK01: "",
        DEVICE2M__p__QK02: "",
        DEVICE2M__p__QK03: "",
        DEVICE2M__p__QK04: "",
        DEVICE2M__p__QK05: "",
        DEVICE2M__p__QK06: "",
        DEVICE2M__p__RSF01: "",
        DEVICE2M__p__RSF02: "",
        DEVICE2M__p__RSF03: "",
        DEVICE2M__p__XK01: "",
        DEVICE2M__p__XK02: "",
        DEVICE2M__p__XK03: "",
        DEVICE2M__p__XK04: "",
        DEVICE2M__p__XK05: "",
        DEVICE2M__p__XK06: "",
        DEVICE2M__p__XK07: "",
        DEVICE2M__p__XK08: "",
        DEVICE2M__p__XK09: "",
        DEVICE2S: "",
        DEVICE2V: "",
        DEVICE3M__p__MAN01: "",
        DEVICE3M__p__MAN02: "",
        DEVICE3M__p__MAN03: "",
        DEVICE3M__p__MAN04: "",
        DEVICE3M__p__MAN05: "",
        DEVICE3M__p__MAN06: "",
        DEVICE3M__p__MAN07: "",
        DEVICE3M__p__PID01: "",
        DEVICE3M__p__PID02: "",
        DEVICE3M__p__PID03: "",
        DEVICE3M__p__QK01: "",
        DEVICE3M__p__QK02: "",
        DEVICE3M__p__QK03: "",
        DEVICE3M__p__QK04: "",
        DEVICE3M__p__QK05: "",
        DEVICE3M__p__QK06: "",
        DEVICE3M__p__RSF01: "",
        DEVICE3M__p__RSF02: "",
        DEVICE3M__p__RSF03: "",
        DEVICE3M__p__XK01: "",
        DEVICE3M__p__XK02: "",
        DEVICE3M__p__XK03: "",
        DEVICE3M__p__XK04: "",
        DEVICE3M__p__XK05: "",
        DEVICE3M__p__XK06: "",
        DEVICE3M__p__XK07: "",
        DEVICE3M__p__XK08: "",
        DEVICE3M__p__XK09: "",
        DEVICE3S: "",
        DEVICE3V: "",
        DEVICE4M__p__MAN01: "",
        DEVICE4M__p__MAN02: "",
        DEVICE4M__p__MAN03: "",
        DEVICE4M__p__MAN04: "",
        DEVICE4M__p__MAN05: "",
        DEVICE4M__p__MAN06: "",
        DEVICE4M__p__MAN07: "",
        DEVICE4M__p__PID01: "",
        DEVICE4M__p__PID02: "",
        DEVICE4M__p__PID03: "",
        DEVICE4M__p__QK01: "",
        DEVICE4M__p__QK02: "",
        DEVICE4M__p__QK03: "",
        DEVICE4M__p__QK04: "",
        DEVICE4M__p__QK05: "",
        DEVICE4M__p__QK06: "",
        DEVICE4M__p__RSF01: "",
        DEVICE4M__p__RSF02: "",
        DEVICE4M__p__RSF03: "",
        DEVICE4M__p__XK01: "",
        DEVICE4M__p__XK02: "",
        DEVICE4M__p__XK03: "",
        DEVICE4M__p__XK04: "",
        DEVICE4M__p__XK05: "",
        DEVICE4M__p__XK06: "",
        DEVICE4M__p__XK07: "",
        DEVICE4M__p__XK08: "",
        DEVICE4M__p__XK09: "",
        DEVICE4S: "",
        DEVICE4V: "",
        DEVICE5M__p__MAN01: "",
        DEVICE5M__p__MAN02: "",
        DEVICE5M__p__MAN03: "",
        DEVICE5M__p__MAN04: "",
        DEVICE5M__p__MAN05: "",
        DEVICE5M__p__MAN06: "",
        DEVICE5M__p__MAN07: "",
        DEVICE5M__p__PID01: "",
        DEVICE5M__p__PID02: "",
        DEVICE5M__p__PID03: "",
        DEVICE5M__p__QK01: "",
        DEVICE5M__p__QK02: "",
        DEVICE5M__p__QK03: "",
        DEVICE5M__p__QK04: "",
        DEVICE5M__p__QK05: "",
        DEVICE5M__p__QK06: "",
        DEVICE5M__p__RSF01: "",
        DEVICE5M__p__RSF02: "",
        DEVICE5M__p__RSF03: "",
        DEVICE5M__p__XK01: "",
        DEVICE5M__p__XK02: "",
        DEVICE5M__p__XK03: "",
        DEVICE5M__p__XK04: "",
        DEVICE5M__p__XK05: "",
        DEVICE5M__p__XK06: "",
        DEVICE5M__p__XK07: "",
        DEVICE5M__p__XK08: "",
        DEVICE5M__p__XK09: "",
        DEVICE5S: "",
        DEVICE5V: "",
        DEVICE7M__p__MAN01: "",
        DEVICE7M__p__MAN02: "",
        DEVICE7M__p__MAN03: "",
        DEVICE7M__p__MAN04: "",
        DEVICE7M__p__MAN05: "",
        DEVICE7M__p__MAN06: "",
        DEVICE7M__p__MAN07: "",
        DEVICE7M__p__MAN08: "",
        DEVICE7M__p__MAN09: "",
        DEVICE7M__p__PID01: "",
        DEVICE7M__p__PID02: "",
        DEVICE7M__p__PID03: "",
        DEVICE7M__p__QK01: "",
        DEVICE7M__p__QK02: "",
        DEVICE7M__p__QK03: "",
        DEVICE7M__p__QK04: "",
        DEVICE7M__p__QK05: "",
        DEVICE7M__p__QK06: "",
        DEVICE7M__p__RSF01: "",
        DEVICE7M__p__RSF02: "",
        DEVICE7M__p__RSF03: "",
        DEVICE7M__p__XK01: "",
        DEVICE7M__p__XK02: "",
        DEVICE7M__p__XK03: "",
        DEVICE7M__p__XK04: "",
        DEVICE7M__p__XK05: "",
        DEVICE7M__p__XK06: "",
        DEVICE7M__p__XK07: "",
        DEVICE7M__p__XK08: "",
        DEVICE7M__p__XK09: "",
        DEVICE7M__p__XK10: "",
        DEVICE7M__p__XK11: "",
        DEVICE7M__p__XK12: "",
        DEVICE7M__p__XK13: "",
        DEVICE7M__p__XK14: "",
        DEVICE7M__p__XK15: "",
        DEVICE7M__p__XK16: "",
        DEVICE7M__p__XK17: "",
        DEVICE7M__p__XK18: "",
        DEVICE7M__p__XK19: "",
        DEVICE7M__p__XK20: "",
        DEVICE7S: "",
        DEVICE7V: "",
        FYF1_LNS: "",
        FYF1_LNS__p__HSCHARC_0: "",
        FYF1_LNS__p__HSCHARC_1: "",
        FYF1_LQS: "",
        FYF1_LQS__p__HSCHARC_0: "",
        FYF1_LQS__p__HSCHARC_1: "",
        FYF1_ZQ: "",
        FYF1_ZQ__p__HSCHARC_0: "",
        FYF1_ZQ__p__HSCHARC_1: "",
        FYF2_LNS__p__HSCHARC_0: "",
        FYF2_LNS__p__HSCHARC_1: "",
        FYF2_LQS__p__HSCHARC_0: "",
        FYF2_LQS__p__HSCHARC_1: "",
        FYF2_ZQ__p__HSCHARC_0: "",
        FYF2_ZQ__p__HSCHARC_1: "",
        FYF3_LNS__p__HSCHARC_0: "",
        FYF3_LNS__p__HSCHARC_1: "",
        FYF3_LQS__p__HSCHARC_0: "",
        FYF3_LQS__p__HSCHARC_1: "",
        FYF3_ZQ__p__HSCHARC_0: "",
        FYF3_ZQ__p__HSCHARC_1: "",
        FYF4_LNS__p__HSCHARC_0: "",
        FYF4_LNS__p__HSCHARC_1: "",
        FYF4_LQS__p__HSCHARC_0: "",
        FYF4_LQS__p__HSCHARC_1: "",
        FYF4_ZQ__p__HSCHARC_0: "",
        FYF4_ZQ__p__HSCHARC_1: "",
        FYF5_LNS__p__HSCHARC_0: "",
        FYF5_LNS__p__HSCHARC_1: "",
        FYF5_LQS__p__HSCHARC_0: "",
        FYF5_LQS__p__HSCHARC_1: "",
        FYF5_ZQ__p__HSCHARC_0: "",
        FYF5_ZQ__p__HSCHARC_1: "",
        PUB: "",
        RECIPE: "",
        TX__p__bcd: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "jlt") {
      let rfinfolist = {
        DB1M__p__XK01: "",
        DB1M__p__XK02: "",
        DB1M__p__XK03: "",
        DB1M__p__XK04: "",
        DB1M__p__XK05: "",
        DB1M__p__XK06: "",
        DB1M__p__XK07: "",
        DB1M__p__XK08: "",
        DB1M__p__XK09: "",
        DB1M__p__XK10: "",
        DB1M__p__XK11: "",
        DB1M__p__XK12: "",
        DB1M__p__XK13: "",
        DB1M__p__XK14: "",
        DB1M__p__XK15: "",
        DB1M__p__XK16: "",
        DB1M__p__XK17: "",
        DB1M__p__XK18: "",
        DB1M__p__XK19: "",
        DB1M__p__XK20: "",
        DB1M__p__XK21: "",
        DB1M__p__XK22: "",
        DB1M__p__XK23: "",
        DB1M__p__XK24: "",
        DB1M__p__XK25: "",
        DB1M__p__XK26: "",
        DB1M__p__XK27: "",
        DB1M__p__XK28: "",
        DB1M__p__XK29: "",
        DB1M__p__XK30: "",
        DB1M__p__XK31: "",
        DB1M__p__XK32: "",
        DB1M__p__XK33: "",
        DB1M__p__XK34: "",
        DB1M__p__XK35: "",
        DB1M__p__XK36: "",
        DB1M__p__XK37: "",
        DB1M__p__XK38: "",
        DB1M__p__XK39: "",
        DB1M__p__XK40: "",
        DB1M__p__XK42: "",
        DB1M__p__XK43: "",
        DB1M__p__XK44: "",
        DB1M__p__XK45: "",
        DB1M__p__XK46: "",
        DB1M__p__XK47: "",
        DB1M__p__XK01__p__MHZSY_0: "",
        DB1M__p__XK02__p__MHZSY_0: "",
        DB1M__p__XK03__p__MHZSY_0: "",
        DB1M__p__XK04__p__MHZSY_0: "",
        DB1M__p__XK05__p__MHZSY_0: "",
        DB1M__p__XK06__p__MHZSY_0: "",
        DB1M__p__XK07__p__MHZSY_0: "",
        DB1M__p__XK08__p__MHZSY_0: "",
        DB1M__p__XK09__p__MHZSY_0: "",
        DB1M__p__XK10__p__MHZSY_0: "",
        DB1M__p__XK11__p__MHZSY_0: "",
        DB1M__p__XK12__p__MHZSY_0: "",
        DB1M__p__XK13__p__MHZSY_0: "",
        DB1M__p__XK14__p__MHZSY_0: "",
        DB1M__p__XK15__p__MHZSY_0: "",
        DB1M__p__XK16__p__MHZSY_0: "",
        DB1M__p__XK17__p__MHZSY_0: "",
        DB1M__p__XK18__p__MHZSY_0: "",
        DB1M__p__XK19__p__MHZSY_0: "",
        DB1M__p__XK20__p__MHZSY_0: "",
        DB1M__p__XK21__p__MHZSY_0: "",
        DB1M__p__XK22__p__MHZSY_0: "",
        DB1M__p__XK23__p__MHZSY_0: "",
        DB1M__p__XK24__p__MHZSY_0: "",
        DB1M__p__XK25__p__MHZSY_0: "",
        DB1M__p__XK26__p__MHZSY_0: "",
        DB1M__p__XK27__p__MHZSY_0: "",
        DB1M__p__XK28__p__MHZSY_0: "",
        DB1M__p__XK29__p__MHZSY_0: "",
        DB1M__p__XK30__p__MHZSY_0: "",
        DB1M__p__XK31__p__MHZSY_0: "",
        DB1M__p__XK32__p__MHZSY_0: "",
        DB1M__p__XK33__p__MHZSY_0: "",
        DB1M__p__XK34__p__MHZSY_0: "",
        DB1M__p__XK35__p__MHZSY_0: "",
        DB1M__p__XK36__p__MHZSY_0: "",
        DB1M__p__XK37__p__MHZSY_0: "",
        DB1M__p__XK38__p__MHZSY_0: "",
        DB1M__p__XK39__p__MHZSY_0: "",
        DB1M__p__XK40__p__MHZSY_0: "",
        DB1M__p__XK41__p__MHZSY_0: "",
        DB1M__p__XK42__p__MHZSY_0: "",
        DB1M__p__XK43__p__MHZSY_0: "",
        DB1M__p__XK44__p__MHZSY_0: "",
        DB1M__p__RSF01: "",
        DB1M__p__RSF02: "",
        DB1M__p__RSF03: "",
        DB1M__p__RSF04: "",
        DB1M__p__RSF05: "",
        DB1M__p__PID01: "",
        DB1M__p__PID02: "",
        DB1M__p__PID03: "",
        DB1M__p__QK01: "",
        DB1M__p__QK02: "",
        DB1M__p__QK03: "",
        DB1M__p__QK04: "",
        DB1M__p__QK05: "",
        DB1M__p__QK06: "",
        DB1M__p__QK07: "",
        DB1M__p__QK08: "",
        DB1M__p__QK09: "",
        DB1M__p__QK10: "",
        DB1M__p__QK11: "",
        DB1M__p__QK12: "",
        DB1M__p__QK13: "",
        DB1M__p__QK14: "",
        DB1M__p__QK15: "",
        DB1M__p__MAN01: "",
        DB1M__p__MAN02: "",
        DB1M__p__MAN03: "",
        DB1M__p__MAN04: "",
        DB1M__p__MAN05: "",
        DB1M__p__MAN06: "",
        DB1M__p__MAN07: "",
        DB1M__p__MAN08: "",
        DB1M__p__MAN09: "",
        DB1M__p__MAN10: "",
        RECIPE: "",
        DB1S: "",
        DB1S__p__ZXHS01: "",
        DB1S__p__ZXHS02: "",
        DB1S__p__ZXHS03: "",
        DB1S__p__ZXHS04: "",
        DB1S__p__ZXHS05: "",
        DB1S__p__ZXHS06: "",
        DB1S__p__ZXHS07: "",
        DB1S__p__ZXHS08: "",
        DB1S__p__ZXHS09: "",
        DB1S__p__ZXHS10: "",
        DB1M__p__MAN11: "",
        DB1M__p__MAN12: "",
        DB1M__p__MAN13: "",
        DB1M__p__MAN14: "",
        DB1M__p__MAN15: "",
        DB1M__p__MAN16: "",
        DB1M__p__MAN17: "",
        DB1M__p__MAN18: "",
        DB1M__p__MAN19: "",
        DB1M__p__MAN20: "",
        DB1M__p__MAN21: "",
        DB1M__p__MAN22: "",
        DB1M__p__MAN23: "",
        DB1M__p__MAN24: "",
        DB1M__p__MAN25: "",
        DB1M__p__MAN26: "",
        DB1M__p__MAN27: "",
        DB1M__p__MAN28: "",
        DB1M__p__MAN29: "",
        DB1M__p__MAN30: "",
        DB1M__p__MAN31: "",
        DB1M__p__MAN32: "",
        DB1M__p__MAN33: "",
        DB1M__p__MAN34: "",
        DB1M__p__MAN35: "",
        DB1M__p__MAN36: "",
        DB1M__p__MAN37: "",
        DB1M__p__MAN38: "",
        DB1M__p__MAN39: "",
        DB1M__p__MAN40: "",
        DB1M__p__MAN41: "",
        DB1M__p__MAN42: "",
        DB1M__p__MAN43: "",
        DB1M__p__MAN44: "",
        DB1M__p__MAN45: "",
        DB1M__p__MAN46: "",
        DB1M__p__MAN47: "",
        DB1V: "",
        PUB: "",
        DB1M__p__QK16: "",
        DB1M: "",
        FYFTJ: "",
        FYFZZ: "",
        FZ_FYF_CONTROL: "",
        FZ_FYF_CONTROL__p__HSCHARC_0: "",
        FZ_FYF_CONTROL__p__HSCHARC_1: "",
        FZ_GTJ: "",
        FZ_GZ_CONTROL__p__HSCHARC_0: "",
        FZ_JLTTCCL: "",
        FZ_JLT_CONTROL: "",
        GZZZ2: "",
        JLZZ: "",
        LLLJ: "",
        PATHY: "",
      };
      this.infoList = rfinfolist;
    } else if (this.deviceType.type == "gz") {
      let rfinfolist = {
        DB1M__p__MAN01: "",
        DB1M__p__MAN02: "",
        DB1M__p__MAN03: "",
        DB1M__p__MAN04: "",
        DB1M__p__MAN05: "",
        DB1M__p__MAN06: "",
        DB1M__p__MAN07: "",
        DB1M__p__MAN08: "",
        DB1M__p__MAN09: "",
        DB1M__p__MAN10: "",
        DB1M__p__MAN11: "",
        DB1M__p__MAN12: "",
        DB1M__p__MAN13: "",
        DB1M__p__MAN14: "",
        DB1M__p__MAN15: "",
        DB1M__p__MAN16: "",
        DB1M__p__MAN17: "",
        DB1M__p__MAN18: "",
        DB1M__p__MAN19: "",
        DB1M__p__MAN20: "",
        DB1M__p__PID01: "",
        DB1M__p__QK01: "",
        DB1M__p__QK02: "",
        DB1M__p__QK03: "",
        DB1M__p__QK04: "",
        DB1M__p__QK05: "",
        DB1M__p__QK06: "",
        DB1M__p__QK07: "",
        DB1M__p__RSF01: "",
        DB1M__p__XK01: "",
        DB1M__p__XK02: "",
        DB1M__p__XK03: "",
        DB1M__p__XK04: "",
        DB1M__p__XK05: "",
        DB1M__p__XK06: "",
        DB1M__p__XK07: "",
        DB1M__p__XK08: "",
        DB1M__p__XK09: "",
        DB1M__p__XK10: "",
        DB1M__p__XK11: "",
        DB1M__p__XK12: "",
        DB1M__p__XK13: "",
        DB1M__p__XK14: "",
        DB1M__p__XK15: "",
        DB1M__p__XK16: "",
        DB1M__p__XK17: "",
        DB1M__p__XK18: "",
        DB1M__p__XK19: "",
        DB1M__p__XK20: "",
        DB1S: "",
        DB1V: "",
        DB2M__p__MAN01: "",
        DB2M__p__MAN02: "",
        DB2M__p__MAN03: "",
        DB2M__p__MAN04: "",
        DB2M__p__MAN05: "",
        DB2M__p__MAN06: "",
        DB2M__p__MAN07: "",
        DB2M__p__MAN08: "",
        DB2M__p__MAN09: "",
        DB2M__p__MAN10: "",
        DB2M__p__MAN11: "",
        DB2M__p__MAN12: "",
        DB2M__p__MAN13: "",
        DB2M__p__MAN14: "",
        DB2M__p__MAN15: "",
        DB2M__p__MAN16: "",
        DB2M__p__MAN17: "",
        DB2M__p__MAN18: "",
        DB2M__p__MAN19: "",
        DB2M__p__MAN20: "",
        DB2M__p__PID01: "",
        DB2M__p__QK01: "",
        DB2M__p__QK02: "",
        DB2M__p__QK03: "",
        DB2M__p__QK04: "",
        DB2M__p__QK05: "",
        DB2M__p__QK06: "",
        DB2M__p__QK07: "",
        DB2M__p__RSF01: "",
        DB2M__p__XK01: "",
        DB2M__p__XK02: "",
        DB2M__p__XK03: "",
        DB2M__p__XK04: "",
        DB2M__p__XK05: "",
        DB2M__p__XK06: "",
        DB2M__p__XK07: "",
        DB2M__p__XK08: "",
        DB2M__p__XK09: "",
        DB2M__p__XK10: "",
        DB2M__p__XK11: "",
        DB2M__p__XK12: "",
        DB2M__p__XK13: "",
        DB2M__p__XK14: "",
        DB2M__p__XK15: "",
        DB2M__p__XK16: "",
        DB2M__p__XK17: "",
        DB2M__p__XK18: "",
        DB2M__p__XK19: "",
        DB2M__p__XK20: "",
        DB2S: "",
        DB2V: "",
        PUB: "",
        RECIPE: "",
      };
      this.infoList = rfinfolist;
    }
  },
  mounted() {},
  computed: {},
  methods: {
    // 获取燃烧流程页面接口
    getInfo() {
      if (
        this.deviceType.type == "jrl" ||
        this.deviceType.type == "rfl" ||
        this.deviceType.type == "rql" ||
        this.deviceType.type == "cfb" ||
        this.deviceType.type == "mfl"
      ) {
        getIndexInfo1({
          // getIndexInfo1({
          device: this.deviceType.device,
          // device:"BCS335" ,
          cp_type: this.deviceType.type,
        })
          .then((res) => {
            if (res.info.code == 201) {
              if (this.$route.path != "/show") {
                this.$message({
                  message: "当前页面为历史数据，暂时无法显示实时数据",
                  type: "warning",
                });
                let projectData = JSON.parse(localStorage.getItem("infoList"));
                this.infoList = projectData;
                this.info = res.info;
                clearInterval(this.timer);
              }
            } else {
              this.fullscreenLoading = false;
              this.infoList = res.data;
              // console.log(this.infoList);
            }
            // 表格一数据
          })
          .catch((err) => {
            this.fullscreenLoading = false;
          });
      } else {
        getIndexInfo({
          device: this.deviceType.device,
          // device:"BCS335" ,
          cp_type: this.deviceType.type,
        })
          .then((res) => {
            if (res.info.code == 201) {
              if (this.$route.path != "/show") {
                this.$message({
                  message: "当前页面为历史数据，暂时无法显示实时数据",
                  type: "warning",
                });
                let projectData = JSON.parse(localStorage.getItem("infoList"));
                this.infoList = projectData;
                this.info = res.info;
                clearInterval(this.timer);
              }
            } else {
              this.fullscreenLoading = false;
              this.infoList = res.data;
            }
            // 表格一数据
          })
          .catch((err) => {
            this.fullscreenLoading = false;
          });
      }
    },
  },
};
