import Vue from "vue"
import VueRouter from "vue-router"
import indexRoutes from "./modules/index"
import authRoutes from "./modules/auth"
import otherRoutes from "./modules/other"
import mapRoutes from "./modules/map"

// 屏蔽跳转到本身时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.location.open(location, "_self")

    return originalPush.call(this, location).catch(err => err)
}

const originalResolve = VueRouter.prototype.resolve
VueRouter.prototype.pushToTab = function pushToTab(location) {
	if(!location) return;
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.open(location, "_blank")

    const { href } = originalResolve.call(this, location)
    window.open(href, "_blank")
}

Vue.use(VueRouter)

const mainRouters = [
    indexRoutes,
    authRoutes,
    otherRoutes,
    mapRoutes,
    {
        path: "/closed",
        name: "closed",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/closed.vue")
    },
    {
        path: "*",
        name: "error",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/error.vue")
    }
]

const router = new VueRouter({
	mode:'hash',
	base : '/',
    routes: mainRouters
})

// 路由守卫，控制访问权限
router.beforeEach((to, from, next) => {
  


    next()
})

router.afterEach((to, from) => {

})

export default router
